<script setup lang="ts">
import useFooterState from '~core/components/Layout/TheFooter/useFooterState';

const {
    isEnabled: isFooterEnabled,
} = useFooterState();
</script>

<template>
    <div>
        <TheBaseLayout>
            <div
                class="
                    grid
                    h-full
                    grid-cols-1
                    grid-rows-[min-content_auto_min-content]
                    bg-background-1
                "
            >
                <div class="z-20 col-span-full row-span-1 row-start-1">
                    <slot name="header">
                        <TheHeader />
                    </slot>
                </div>

                <div class="col-span-full row-span-2 row-start-1">
                    <slot name="main">
                        <!--
                            TODO:
                            Suspense prevents double mount of pages. why? no fucking clue
                            https://github.com/nuxt/nuxt/issues/30200
                        -->
                        <Suspense>
                            <NuxtPage />
                        </Suspense>
                    </slot>
                </div>

                <div :class="isFooterEnabled ? 'h-[72px] md:h-[88px]' : 'h-0'">
                    <slot name="footer">
                        <TheFooter />
                    </slot>
                </div>
            </div>
        </TheBaseLayout>
    </div>
</template>
