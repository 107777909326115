<script setup lang="ts">
</script>

<template>
    <div>
        <TheBaseLayout>
            <div
                class="
                    grid
                    h-full
                    grid-rows-[min-content_auto]
                "
            >
                <div class="z-20 col-span-full row-span-1 row-start-1">
                    <slot name="header">
                        <TheHeader
                            :is-logo-linked="false"
                            :is-progress-dots-visible="false"
                        />
                    </slot>
                </div>

                <div class="col-span-full row-span-2 row-start-1">
                    <slot name="main">
                        <!--
                            TODO:
                            Suspense prevents double mount of pages. why? no fucking clue
                            https://github.com/nuxt/nuxt/issues/30200
                        -->
                        <Suspense>
                            <NuxtPage />
                        </Suspense>
                    </slot>
                </div>
            </div>
        </TheBaseLayout>
    </div>
</template>
