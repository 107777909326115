<script setup lang="ts">
import useFooterState from './useFooterState';

const {
    isEnabled,
    isPriceVisible,
    backwardCallback,
    backwardLabel,
    canGoBack,
    layout,
} = useFooterState();

const styles = computed(() => {
    const basicStyles = {
        '--w-container': 'calc(var(--dvw) - (var(--w-container-gap) * 2))',
        '--w-footer': 'calc(var(--w-container) - var(--w-aside) - var(--w-col-gap))',
        '--offset-footer': 'calc(var(--w-container-gap) + (var(--w-aside) + var(--w-col-gap)))',
    };

    return basicStyles;
});

const classes = computed(() => {
    const basicClasses = [
        'fixed',
        'z-10',
        'bottom-0',
        'md:bottom-4',
        'flex',
        'w-full',
        'h-[4.5rem]',
        'items-center',
        'justify-between',
        'md:transition-all',
        'md:duration-500',
    ];

    const whiteBarClasses = [
        'bg-white',
        'px-container-gap',
        'py-3',
        'border-t',
        'border-shade-1',
        'md:border-t-0',
        'md:rounded',
        'md:px-4',
        'md:shadow-lg',
    ];

    const floatingClasses = [
        'max-md:pr-container-gap',
        'max-md:pb-container-gap',
    ];

    const asidePosClasses = [
        'md:w-[var(--w-footer)]',
        'md:max-w-[var(--w-footer)]',
        'md:translate-x-[var(--offset-footer)]',
    ];

    switch (layout.value) {
        case 'centerCheckout':
            return [
                ...basicClasses,
                ...whiteBarClasses,
                'md:w-[var(--w-max-narrow)]',
                'md:translate-x-[calc(50vw_-_(var(--w-max-narrow)/2))]',
            ];
        case 'centerProse':
            return [
                ...basicClasses,
                ...whiteBarClasses,
                'md:w-[var(--w-max-prose)]',
                'md:translate-x-[calc(50vw_-_(var(--w-max-prose)/2))]',
            ];
        case 'floating':
            return [
                ...basicClasses,
                ...asidePosClasses,
                ...floatingClasses,
            ];
        case 'withSidebar':
        default:
            return [
                ...basicClasses,
                ...asidePosClasses,
                ...whiteBarClasses,
            ];
    }
});
</script>

<template>
    <footer
        v-if="isEnabled"
        :class="classes"
        :style="styles"
    >
        <div class="h-full">
            <Transition name="fade">
                <TheFooterPrice v-if="isPriceVisible" />
            </Transition>
        </div>

        <div class="flex min-[400px]:space-x-4">
            <button
                v-if="!!backwardCallback"
                :disabled="!canGoBack"
                class="justify-self-end px-4 transition-colors hover:text-shade-6 disabled:text-shade-4"
                @click="backwardCallback"
            >
                <span class="typo-button max-sm:hidden">
                    {{ backwardLabel.length ? backwardLabel : $t('core.buttons.back') }}
                </span>
                <Icon name="tot:backward" class="size-4 sm:hidden" :aria-label="$t('core.buttons.back')" />
            </button>

            <TheFooterForwardButton />
        </div>
    </footer>
</template>

<style scoped>
</style>
