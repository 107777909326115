<script setup lang="ts">
import useFooterState from './useFooterState';

const {
    forwardCallback,
    forwardLabel,
    layout,
    canProceed,
    isProceedDisabledVisually,
} = useFooterState();

// After using forwarwd button, block for 1 second
// to prevent accidental double click triggering the next button
// of the next page already
const throttledProceed = useThrottleFn(proceed, 1000);
function proceed() {
    forwardCallback.value();
}

const buttonLayout = computed(() => layout.value === 'floating' ? 'circle' : 'default');

const classes = computed(() => {
    const basicClasses = [
        'whitespace-nowrap',
        'overflow-hidden',
        'typo-button',
        'bg-brand-a',
        'text-brand-b',
        'disabled:bg-brand-a-2',
        'disabled:text-brand-a-3',
        'data-[disabled-visually=true]:bg-brand-a-2',
        'data-[disabled-visually=true]:text-brand-a-3',
        'slide-bg',
    ];

    const defaultClasses = [
        'px-4',
        'py-3.5',
        'rounded-sm',
    ];

    const circleClasses = [
        'flex',
        'items-center',
        'justify-center',
        'rounded-full',
        'aspect-square',
        'p-4',
        'size-[75px]',
        '-translate-y-2',
    ];

    switch (buttonLayout.value) {
        case 'circle':
            return [
                ...basicClasses,
                ...circleClasses,
            ];

        case 'default':
        default:
            return [
                ...basicClasses,
                ...defaultClasses,
            ];
    }
});
</script>

<template>
    <button
        v-if="!!forwardCallback"
        :class="classes"
        :disabled="!canProceed"
        :data-disabled-visually="isProceedDisabledVisually"
        @click="throttledProceed"
    >
        {{ forwardLabel }}
    </button>
</template>
