import { resolveScriptKey } from "unhead";
import { defu } from "defu";
import { useScript as _useScript } from "@unhead/vue";
import { injectHead, onNuxtReady, useHead, useNuxtApp, useRuntimeConfig, reactive } from "#imports";
function useNuxtScriptRuntimeConfig() {
  return useRuntimeConfig().public["nuxt-scripts"];
}
export function useScript(input, options) {
  input = typeof input === "string" ? { src: input } : input;
  options = defu(options, useNuxtScriptRuntimeConfig()?.defaultScriptOptions);
  const rel = options.trigger === "onNuxtReady" ? "preload" : "preconnect";
  const id = resolveScriptKey(input);
  if (options.trigger !== "server" && (rel === "preload" || !input.src.startsWith("/"))) {
    useHead({
      link: [
        {
          rel,
          as: rel === "preload" ? "script" : void 0,
          href: input.src,
          crossorigin: input.src.startsWith("/") ? void 0 : typeof input.crossorigin !== "undefined" ? input.crossorigin : "anonymous",
          key: `nuxt-script-${id}`,
          tagPriority: rel === "preload" ? "high" : 0,
          fetchpriority: "low"
        }
      ]
    });
  }
  if (options.trigger === "onNuxtReady") {
    options.trigger = onNuxtReady;
  }
  const nuxtApp = useNuxtApp();
  nuxtApp.$scripts = nuxtApp.$scripts || reactive({});
  const exists = !!nuxtApp.$scripts?.[id];
  if (import.meta.client) {
    if (!exists) {
      performance?.mark?.("mark_feature_usage", {
        detail: {
          feature: options.performanceMarkFeature ?? `nuxt-scripts:${id}`
        }
      });
    }
  }
  const instance = _useScript(input, options);
  nuxtApp.$scripts[id] = instance;
  if (import.meta.dev && import.meta.client) {
    let syncScripts = function() {
      nuxtApp._scripts[instance.id] = payload;
      nuxtApp.hooks.callHook("scripts:updated", { scripts: nuxtApp._scripts });
    };
    if (exists) {
      return instance;
    }
    const payload = {
      ...options.devtools,
      src: input.src,
      $script: null,
      events: []
    };
    nuxtApp._scripts = nuxtApp._scripts || {};
    if (!nuxtApp._scripts[instance.id]) {
      const head = injectHead();
      head.hooks.hook("script:updated", (ctx) => {
        if (ctx.script.id !== instance.id)
          return;
        payload.events.push({
          type: "status",
          status: ctx.script.status,
          at: Date.now()
        });
        payload.$script = instance;
        syncScripts();
      });
      head.hooks.hook("script:instance-fn", (ctx) => {
        if (ctx.script.id !== instance.id || String(ctx.fn).startsWith("__v_"))
          return;
        payload.events.push({
          type: "fn-call",
          fn: ctx.fn,
          at: Date.now()
        });
        syncScripts();
      });
      payload.$script = instance;
      payload.events.push({
        type: "status",
        status: "awaitingLoad",
        trigger: options?.trigger,
        at: Date.now()
      });
      syncScripts();
    }
  }
  return instance;
}
